// extracted by mini-css-extract-plugin
export var back = "category-module--back--fto+o";
export var itemDetails = "category-module--item-details--Pqw+4";
export var itemImage = "category-module--item-image--H9uAd";
export var itemLine = "category-module--item-line--a64be";
export var itemPrice = "category-module--item-price--J0HN2";
export var itemWithImage = "category-module--item-with-image--YpIx1";
export var itemsList = "category-module--items-list--WTMoM";
export var itemsTitle = "category-module--items-title--wReOZ";
export var menuBgCocktails = "category-module--menu-bg-cocktails--jdGzA";
export var menuBgHookah = "category-module--menu-bg-hookah--I0431";
export var modalClose = "category-module--modal-close--t+hE+";
export var modalImage = "category-module--modal-image--4B0rr";
export var modalTitle = "category-module--modal-title--gvHqE";
export var wrapper = "category-module--wrapper----5ub";