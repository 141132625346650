// extracted by mini-css-extract-plugin
export var form = "LeaveReview-module--form--dYyD6";
export var formRow = "LeaveReview-module--form-row--6utT6";
export var input = "LeaveReview-module--input--EaECI";
export var label = "LeaveReview-module--label--02b+b";
export var modalFinishTitle = "LeaveReview-module--modal-finish-title--mkKWK";
export var modalReviewFinishBg = "LeaveReview-module--modal-review-finish-bg--jkKbk";
export var modalReviewFormBg = "LeaveReview-module--modal-review-form-bg--D0IDe";
export var modalReviewOverlay = "LeaveReview-module--modal-review-overlay--so3Cs";
export var modalReviewWelcomeBg = "LeaveReview-module--modal-review-welcome-bg--rAl0r";
export var reviewDetails = "LeaveReview-module--review-details--8flhu";
export var reviewInfoBlock = "LeaveReview-module--review-info-block--+J7yF";
export var reviewLogo = "LeaveReview-module--review-logo--lvxez";
export var reviewStars = "LeaveReview-module--review-stars--ic0aL";
export var reviewTitle = "LeaveReview-module--review-title--mXo5C";